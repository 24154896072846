import React from 'react';

const TitheDisplay = ({titheTotal, frequency}) => {
    if(typeof titheTotal !== 'undefined'){
        return (
            <div className="columns is-centered">
                <div className="column is-three-quarters">
                    <div className='box has-margin-top-40 has-text-centered'>
                    <h4 className="subtitle is-size-4 is-size-5-mobile" style={{marginBottom: 0}}>Your tithe is:</h4>
                        <h2 className="is-size-2 is-size-3-mobile has-text-success">
                            {titheTotal}
                        </h2>
                        <h4 className="subtitle is-size-4 is-size-5-mobile" style={{marginBottom: 0}}>{frequency}</h4>
                    </div>
                </div>
            </div>
        )        
    } else {
        return null
    }
}

export default TitheDisplay