import React from 'react'
//import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import TitheCalculator from '../components/TitheCalculator'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'

export const HomePageTemplate = ({ data }) => {

  return (
    <div>
      <Navbar />
      <section style={{marginBottom: '4rem'}}>
        <div className="container">
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <div className="section">
                <h1 className="title is-size-1 is-size-2-mobile has-text-weight-bold has-text-centered">
                  Tithe Calculator
                </h1>
                <p className="subtitle is-size-5 has-text-dark-secondary has-text-centered">Enter the amount on your paycheck before taxes and we'll help you find your tithe!</p>
                <TitheCalculator />
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  )
}



const HomePage = () => {
  //const { markdownRemark: post } = data

  return (
    <Layout>
      <HomePageTemplate />
    </Layout>
  )
}

export default HomePage

// export const homePageQuery = graphql`
//   query HomePage($id: String!) {
//     markdownRemark(id: { eq: $id }) {
//       html
//       frontmatter {
//         title
//       }
//     }
//   }
// `
