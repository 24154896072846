import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import TitheDisplay from '../components/TitheDisplay'

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 2
  })

const formatterDecimal = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
})

const responseUrl = "https://script.google.com/macros/s/AKfycbyVC5PasiDoxSgFzAOaXhopj0uc9akjCSOpG-GZwTptNY8UsjOz/exec"

class TitheCalculator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        currencySymbol: '$',
        amount: '',
        frequency: 'Every week',
        titheTotal: ''
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.calculateTithe = this.calculateTithe.bind(this);
  }

//   handleChange(event) {
//     this.setState({value: event.target.value});
//   }

handleChange(e) {
    
    const name = e.target.name;
    const value = e.target.value;

    // const value =  Intl.NumberFormat('en-US', { 
    //     minimumFractionDigits: 0, 
    //     maximumFractionDigits: 2 
    //   }).format(e.target.value)

    this.setState({
        [name]: value,
    });
    
}

calculateTithe = (t) => {
    const tithe = t * .10
    const titheFormatted = formatter.format(tithe)

    this.setState({
        titheTotal: formatterDecimal.format(tithe),
        titheFormatted: titheFormatted
    })
    // this.setState((prevState) => {
    //     const tithe = t * .10
    //     const titheFormatted = formatter.format(tithe)
    //     return { 
    //         titheTotal: formatterDecimal.format(tithe),
    //         titheFormatted: titheFormatted
    //     }
    // })
    //console.log(this.state)
}

  handleSubmit(event) {
    event.preventDefault();
    
    //Calculate tithe and properly format to currency based on entered amount.
    
    const tithe = this.state.amount * .10
    const titheFormatted = formatter.format(tithe)
    
    this.setState({
        titheTotal: formatterDecimal.format(tithe),
    })

    const data = {
        Timestamp: '', //Google Sheets auto sets the timestamp
        amount: this.state.amount,
        frequency: this.state.frequency,
        titheTotal: tithe
    };

    // console.log(this.state)
    
    // Get responseUrl and append the data object as query params. This lets us post data with a GET request to get around CORS issues.
    let urlParams = new URL(responseUrl);
    Object.keys(data).forEach(key => urlParams.searchParams.append(key, data[key]))

    // console.log(urlParams)
    // console.log("Data",data)

        //Set button to loading state and disable button
        this.setState({
            isSubmittingClass: 'is-loading'
        });
        
        //Post Data to Google Sheets using GET method
        
        fetch(urlParams,
        {
        method: 'GET',
        mode: "no-cors",
        headers: {
            'Content-Type': 'application/json',
            // 'Access-Control-Allow-Origin': '*'
        },
        }
        ).then(response => {
            console.log('Success! Your tithe has been caluclated!')
            
            // Remove loading state
            this.setState({
                isSubmittingClass: '',
                titheFormatted: titheFormatted
            });
        })
        .catch(error => console.error('Error:', error.message))
        
    return;
  }

  render() {
    return (
        <div>
      <div className="columns is-centered">
        <div className="column is-three-quarters">
          <form id="titheForm" className=" has-padding-0" onSubmit={this.handleSubmit}>
            <div className="field has-addons" style={{marginBottom: "2rem"}}>
              {/* <div className="control">
              <span className="select">
                <select>
                    <option>{this.state.currencySymbol}</option>
                </select>
                </span>
              </div> */}
              <div className="control has-icons-left is-expanded">
                <input
                  className="input is-medium "
                  type="text"
                  id="amount"
                  name="amount"
                  placeholder="Your paycheck amount"
                  autoFocus
                  value={this.state.amount}
                  onChange={this.handleChange}
                />
                <span className="icon is-left is-medium">
                    <FontAwesomeIcon icon="dollar-sign" size="1x" />
                </span>
              </div>
              <div className="control">
                <div className='select'>
                    <select 
                        name="frequency"
                        value={this.state.frequency}
                        onChange={this.handleChange}
                    >
                        <option defaultValue>Every week</option>
                        <option>Every other week</option>
                        <option>Twice a month</option>
                        <option>Once a month</option>
                        <option>Annually</option>
                    </select>
                </div>
                
              </div>
            </div>
            <div className="field has-text-centered">
            <button
                  className={this.state.isSubmittingClass + " button is-primary is-large is-rounded"}
                  disabled={this.state.isSubmittingClass ? true : false }
                  id="searchSubmit"
                  type="submit"
                  value="Calculate My Tithe">
                    <span>Calculate My Tithe</span>
                    <span className="icon is-medium"><FontAwesomeIcon icon="search-dollar" size="1x" /></span>
                </button>
            </div>

          </form>
          
        </div>
      </div>
      <TitheDisplay titheTotal={this.state.titheFormatted} frequency={this.state.frequency} />
    </div>
    );
  }
}

export default TitheCalculator
